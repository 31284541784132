
import qs from 'query-string'
import { computed, defineComponent, onMounted, ref, getCurrentInstance } from 'vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import useRouter from '@/hooks/useRouter'
import { CONNECTOR_TYPES } from '@/vars/general'
import YClientsInit from '../components/YClientsInit.vue'
import DikidiInit from '../components/DikidiInit.vue'
export default defineComponent({
    components: { SpinnerLoader },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const { route, router } = useRouter(root)
        const component = computed<any>(() => {
            const connectorType = route.value.params.connectorType
            if (connectorType === CONNECTOR_TYPES.yClients) return YClientsInit
            if (connectorType === CONNECTOR_TYPES.dikidi) return DikidiInit
        })

        return { component }
    },
})
